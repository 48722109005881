import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import LargeFooter from './LargeFooter';
import styled from '@emotion/styled';

const Layout = ({ children, logo, isHome }) => {
  return (
    <MainContainer>
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            file(relativePath: { eq: "eyrc_works_logo_final.png" }) {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={data => (
          <StyledContainer>
            <StyledHeader>
              <div style={{ maxWidth: '150px' }}>
                <Link to='/'>
                  <Img fixed={data.file.childImageSharp.fixed} />
                </Link>
              </div>
              <nav>
                <StyledNavList>
                  {/* <li>Projects</li> */}
                  <Link href='mailto:info@eyrcworks.com'>
                    <li>Contact</li>
                  </Link>
                </StyledNavList>
              </nav>
            </StyledHeader>
            <StyledContent>{children}</StyledContent>
          </StyledContainer>
        )}
      />
      {!isHome && <LargeFooter />}
    </MainContainer>
  );
};

export default Layout;

const MainContainer = styled.div`
  min-height: 100vh;
`;

const StyledContainer = styled.div`
  max-width: 95vw;
  width: 95vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  padding: 1rem 0 0.5rem;
`;

const StyledContent = styled.main`
  flex-grow: 1;
  padding: 0 2rem;
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 auto;
  width: 100%;
  text-transform: uppercase;
  color: #555;
  padding: 2rem 4rem;

  @media (max-width: 760px) {
    font-size: 12px;
  }

  @media (max-width: 465px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    nav {
      font-size: 0.8rem;
    }
  }
`;

const StyledNavList = styled.ul`
  display: flex;
  flex-direction: row;

  a {
    transition: 300ms;
  }

  li {
    margin-left: 1rem;
    font-weight: bold;
  }

  @media (max-width: 465px) {
    width: 160px;
    justify-content: space-between;
    margin: 0 auto;

    li {
      margin-left: 0;
      margin-top: 0.5rem;
      padding: 2px;
    }
  }
`;
