import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

const LargeFooter = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          file(relativePath: { eq: "eyrc-logo-white.png" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <StyledFooter>
          <div className='content'>
            <Img fixed={data.file.childImageSharp.fixed} />
            <div className='footer-links'>
              <div className='footer-links-section'>
                <a className='email' href='mailto:info@eyrc.com'>
                  info@eyrc.com
                </a>
              </div>
              <div className='footer-links-section'>
                <span></span>
                <span></span>
                <p>Los Angeles</p>
                <p>10865 Washington Blvd</p>
                <p>Culver City, CA 90232</p>
              </div>
              <div className='footer-links-section'>
                <span></span>
                <span></span>
                <p>San Francisco</p>
                <p>60 Brady St</p>
                <p>San Francisco, CA 94103</p>
              </div>
              <div className='footer-links-section'>
                <a href='https://www.facebook.com/EYRCArchitects/'>Facebook</a>
                <a href='https://twitter.com/EYRCarchitects'>Twitter</a>
                <a href='https://www.instagram.com/EYRCarchitects'>Instagram</a>
                <a href='https://www.pinterest.com/eyrcarchitects/'>
                  Pinterest
                </a>
                <a href='https://connect.eyrc.com/blog/?__hstc=42132730.1f722389e87e7a9f3f4a80714451ce1b.1611006412499.1611006412499.1611008339049.2&__hssc=42132730.1.1611008339049&__hsfp=3186672725'>
                  Residential Blog
                </a>
              </div>
            </div>
          </div>
        </StyledFooter>
      )}
    />
  );
};

export default LargeFooter;

const StyledFooter = styled.footer`
  background-color: #222222;
  padding: 3rem 0 4rem 4rem;
  color: #fff;

  .content {
    max-width: 2000px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    text-transform: uppercase;
    margin: 0 auto;
    opacity: 60%;
  }

  .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-self: end;
  }

  .footer-links-section {
    display: grid;
    justify-items: start;
    align-items: end;
    grid-template-rows: repeat(5, 1fr);

    a {
      margin-top: 1rem;
    }

    .email {
      grid-row-start: 5;
    }
  }

  @media (max-width: 1340px) {
    font-size: 12px;
  }

  @media (max-width: 1340px) {
    font-size: 10px;
  }

  @media (max-width: 1000px) {
    font-size: 16px;

    .content {
      display: flex;
      flex-direction: column;
    }

    .footer-links {
      display: flex;
      flex-direction: column;

      .footer-links-section {
        margin-bottom: 2rem;
      }

      .footer-links-section a {
        padding: 0 0 8px;
      }

      .email {
        margin-top: 3rem;
      }
    }

    .footer-links-section {
      display: grid;
      justify-items: start;
      align-items: end;
      grid-template-rows: auto;
    }
  }
`;
